const DEFAULT_LOCALE = "en-US";

export const formatCurrency = (
  value: number | null | undefined,
  currency: string | null | undefined,
  options?: Intl.NumberFormatOptions,
) => {
  const { minimumFractionDigits = 0, ...restOptions } = options || {};

  if (value === null || value === undefined || !currency?.trim()) {
    return null;
  }

  const userLocale = navigator.language || DEFAULT_LOCALE;
  try {
    const formatter = new Intl.NumberFormat(userLocale, {
      style: "currency",
      minimumFractionDigits,
      currency,
      ...restOptions,
    });

    return formatter.format(value);
  } catch (e) {
    return null;
  }
};
